const publicPaths = [
  '/confirm-password',
  '/confirm-password/',
  '/signin',
  '/signin/',
  '/signin-v1',
  '/signin-v1/',
  '/feedback/auth',
  '/feedback/auth/',
  /scheduler\/book-meeting\/*/g,
  /scheduler\/meet-with-me\/*/g,
  '/scheduler/redirect-page',
  '/scheduler/redirect-page/',
];

/**
 * Checks if the given pathName is a public path.
 *
 * @param {string} pathName - The path name to check.
 * @returns {boolean} - Returns true if the pathName is a public path, otherwise false.
 */
export const isPublicPath = (pathName) => {
  for (const path of publicPaths) {
    if (typeof path !== 'string') {
      if (pathName.match(path)) {
        return true;
      }
    } else if (pathName === path) {
      return true;
    }
  }
  return false;
};
