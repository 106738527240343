import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { StoreProvider, useStoreState } from 'easy-peasy';

import {
  PublicRoute,
  PrivateRoute,
  store,
  queryClient,
  QueryParamsProvider,
} from '@webfx/core-web';
import { NotFoundPage } from '@webfx/common-web';
import { SignInV1, SignIn, Logout, ConfirmPassword } from '@webfx/auth-web';
import { FlagOverridesWidget } from '@webfx/web-ui-connected';

import { useFlags, useMeasure, useNotifications } from '@webfx/web-hooks';
import { useVisibilityRepersist } from '@webfx/web-state';

import { PermissionsProvider } from './PermissionsProvider';
import Admin from './Admin';

function App() {
  const { NotificationsContainer } = useNotifications();
  const lastViewed = useStoreState((state) => state.router.lastViewed);
  const flags = useFlags();
  let redirectOnAuthorized = '/';

  if (lastViewed?.pathname && lastViewed?.pathname !== '/logout') {
    redirectOnAuthorized = lastViewed.pathname + lastViewed.search;
  }

  useVisibilityRepersist();
  useMeasure();

  return (
    <>
      <NotificationsContainer />
      <Switch>
        <PublicRoute
          exact
          path="/confirm-password"
          component={ConfirmPassword}
          redirectOnAuthorized={redirectOnAuthorized}
        />
        <PublicRoute
          exact
          path="/signin"
          component={
            Object.keys(flags)?.includes('iamRemovePasswordLogin') && !flags.iamRemovePasswordLogin // prevent flashing of the old sign in page
              ? SignInV1
              : SignIn
          }
          redirectOnAuthorized={redirectOnAuthorized}
        />
        <PublicRoute
          exact
          path="/signin-v1"
          component={SignInV1}
          redirectOnAuthorized={redirectOnAuthorized}
        />
        <PublicRoute
          path="/feedback/auth"
          component={
            Object.keys(flags)?.includes('iamRemovePasswordLogin') && !flags.iamRemovePasswordLogin // prevent flashing of the old sign in page
              ? SignInV1
              : SignIn
          }
          redirectOnAuthorized="/feedback"
          passQueryString
        />
        <Route path="/not-found" component={NotFoundPage} />
        <PrivateRoute path="/logout" component={Logout} />
        <PrivateRoute path="/" component={Admin} />
      </Switch>
    </>
  );
}

/**
 * Renders the main application component with necessary providers.
 * @returns {JSX.Element} The rendered application component.
 */
export default function AppProvider() {
  return (
    <StoreProvider store={store}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <PermissionsProvider>
            <QueryParamsProvider>
              <App />
            </QueryParamsProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            <FlagOverridesWidget />
          </PermissionsProvider>
        </QueryClientProvider>
      </Router>
    </StoreProvider>
  );
}
