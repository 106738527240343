import React, { useState, useEffect } from 'react';

import { useAuth } from '@webfx/web-hooks';
import { PermissionsAbility, PermissionsContext } from '@webfx/web-state';
import { api, Preloader } from '@webfx/core-web';

const PERMISSIONS_REFRESH_INTERVAL_MINS = 60;
const PERMISSIONS_REFRESH_INTERVAL_MS = PERMISSIONS_REFRESH_INTERVAL_MINS * 60 * 1000;

/**
 * PermissionsProvider component is responsible for managing and providing
 * user permissions throughout the application. It fetches the permissions
 * for the authenticated user and updates the PermissionsAbility accordingly.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components that require access to permissions.
 * @returns {JSX.Element} The PermissionsProvider component.
 */
export function PermissionsProvider({ children }) {
  const { userId, setUser, loginTime } = useAuth();
  const accessToken = window.localStorage.getItem('accessToken');

  // because our permissions come from external API and Ability outside of
  // react we have to have a mechanism to force a re-render off the effect.
  // we use a simple counter to force a re-render for the most simple solution.
  const [renderState, setRenderState] = useState(0);

  const permissions = api.useQuery(['permissions', userId], {
    enabled: !!userId && !!accessToken,
    refetchInterval: PERMISSIONS_REFRESH_INTERVAL_MS,
  });

  // Refreshes the user object in local storage every once in a while.
  // This ensures users who never log out recieve updates to their local storage.
  // For example, this keeps the local storage `user.products` in sync with the `core.products` DB table.
  const refreshedUser = api.useQuery(
    [
      'users',
      userId,
      {
        query: {
          $join: {
            roles: true,
            sites: { $join: ['roles'] },
            tokens: { role: 'uploads' },
            companies: { $join: ['status', 'qbo'] },
            products: true,
            teams: true,
            startPage: true,
          },
        },
      },
    ],
    {
      staleTime: PERMISSIONS_REFRESH_INTERVAL_MS,
      cacheTime: PERMISSIONS_REFRESH_INTERVAL_MS,
      enabled:
        !!userId &&
        (!loginTime || Date.parse(loginTime) + PERMISSIONS_REFRESH_INTERVAL_MS < Date.now()),
    }
  );

  useEffect(() => {
    if (permissions.data) {
      PermissionsAbility.update([...permissions.data, { action: 'read', subject: 'platform' }]);
      setRenderState(renderState + 1);
    }
  }, [permissions.data]);

  useEffect(() => {
    if (refreshedUser.data) {
      setUser(refreshedUser.data);
    }
  }, [refreshedUser.data, setUser]);

  return (
    <PermissionsContext.Provider value={PermissionsAbility}>
      {permissions.isLoading || (userId && PermissionsAbility.cannot('read', 'platform')) ? (
        <Preloader />
      ) : (
        children
      )}
    </PermissionsContext.Provider>
  );
}
