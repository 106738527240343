import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Imports, Preloader } from '@webfx/core-web';
import { Downloads } from '@webfx/web-ui-connected';
import { AdminLayout, FullPageLayout, HelpCenter, HelpCenterV1, MyInfo } from '@webfx/common-web';
import {
  useAuth,
  useFlags,
  useFullPageLayout,
  useLaunchDarkly,
  usePageView,
  useStartPage,
} from '@webfx/web-hooks';
import TeamWork from '@webfx/teamwork-web';

const ClientsPermissions = React.lazy(() => import('@webfx/permissions-web'));
const TeamAi = React.lazy(() => import('@webfx/teamai-web'));
const MarketingCloud = React.lazy(() => import('@webfx/marketingcloud-web'));
const ContentGenius = React.lazy(() => import('@webfx/contentgenius-web'));
const CompetitorSpy = React.lazy(() => import('@webfx/competitorspy-web'));
const Playbook = React.lazy(() => import('@webfx/playbook-web'));

/**
 * Main Application Admin
 */
const Admin = () => {
  usePageView();
  useLaunchDarkly();
  const startPage = useStartPage();
  const { switchingUser, userId } = useAuth();
  const flags = useFlags();
  const isFullLayout = useFullPageLayout();

  if (!userId) {
    return null;
  }

  if (switchingUser) {
    return <Preloader />;
  }

  const Layout = isFullLayout ? FullPageLayout : AdminLayout;

  return (
    <Layout>
      <Downloads />
      <Imports />
      <Suspense fallback={<div style={{ display: 'none' }} />}>
        <Switch>
          <Redirect exact path="/" to={startPage} />;
          <Redirect from="/crm" to={'/nutshell'} />
          <Route path="/my-info" component={MyInfo} />
          <Route
            path="/help-center"
            component={flags.helpcenterUpdates ? HelpCenter : HelpCenterV1}
          />
          <Route path="/client-permissions" component={ClientsPermissions} />
          <Route path="/nutshell/:siteId?" component={MarketingCloud} />
          <Route path="/revenuecloudfx" component={MarketingCloud} />
          <Route path="/playbookfx" component={Playbook} />
          <Route path="/competitorspy" component={CompetitorSpy} />
          <Route path="/contentgenius/:siteId?" component={ContentGenius} />
          <Route path="/teamai" component={TeamAi} />
          <Route path="/" component={TeamWork} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default Admin;
